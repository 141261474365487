import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";
import LinearProgress from "@mui/material/LinearProgress";
import DialogContent from "@mui/material/DialogContent";
import DialogHeader from "core/components/DialogHeader";
import { useQuery } from "@apollo/client";
import { ProductQuery } from "arena/queries";
import { useViewer } from "core/components/ViewerProvider";
import ProductPayment from "arena/components/ProductPayment";
import ProductHelpDialog from "arena/components/ProductHelpDialog";
import ReactGA from "react-ga4";
import ProductBody from "./ProductBody";

const PREFIX = "Product";

const classes = {
  title: `${PREFIX}-title`,
};

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(0),
  overflow: "hidden",
  // minHeight: 300,

  [`& .${classes.title}`]: {
    fontWeight: 800,
    color: "white",
    textShadow: "0px 0px 20px black",
    fontSize: "1.8rem",

    [theme.breakpoints.up("sm")]: {
      fontSize: "2rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "2.5rem",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "3rem",
    },
  },
}));

export default function Product({ onClose, state, dispatch }) {
  const [promocodeIds, setPromocodeIds] = useState([]);
  const [helpOpen, setHelpOpen] = useState(false);

  const viewer = useViewer();

  const { data, previousData, loading, refetch } = useQuery(ProductQuery, {
    // fetchPolicy: "network-only",
    fetchPolicy: "cache-and-network",
    variables: {
      id: state.productId,
      promocodeIds,
    },
  });

  const product = data?.product || previousData?.product;

  const onCloseHelpDialog = () => {
    setHelpOpen(false);

    ReactGA.event({
      category: "Product",
      action: "Close help",
      label: product.title,
    });
  };

  const onClickHelpButton = () => {
    setHelpOpen(true);

    ReactGA.event({
      category: "Product",
      action: "Click help button",
      label: product.title,
    });
  };

  const onClickPriceButton = (event, price) => {
    event.preventDefault();

    ReactGA.event({
      category: "Product",
      action: "Click to create payment button",
      label: product.title,
    });

    const { providers } = price;
    const [provider] = providers;

    dispatch({
      type: "create_payment",
      provider,
      providers,
      priceId: price?.id,
      amount: price?.amount,
      recurrent: price?.recurrent,
      productType: product.type,
    });
  };

  return (
    <>
      <DialogHeader onClose={onClose} title={product?.title} />
      <StyledDialogContent>
        {loading && <LinearProgress />}
        <Stack spacing={2}>
          {product && (
            <ProductPayment
              onClickHelpButton={onClickHelpButton}
              onClickPriceButton={onClickPriceButton}
              product={product}
              promocodeIds={promocodeIds}
              setPromocodeIds={setPromocodeIds}
              viewer={viewer}
              refetch={refetch}
            />
          )}
          {product && <ProductBody product={product} />}
        </Stack>
      </StyledDialogContent>
      {product && (
        <ProductHelpDialog
          product={product}
          open={helpOpen}
          onClose={onCloseHelpDialog}
        />
      )}
    </>
  );
}

Product.propTypes = {
  state: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
};
