import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import Video from "core/components/Video";
import RelatedVideo from "core/components/RelatedVideo";
import StreamOfflineScreen from "core/components/StreamOfflineScreen";
import { createEvent } from "core/utils/tracker";
import { useLiveSeekBar } from "core/components/LiveSeekBar";
import { useSubscription } from "@apollo/client";
import debounce from "core/utils/debounce";
import getStreamPlaylistWithOffset from "core/utils/getStreamPlaylistWithOffset";
import ChooseCameraButton from "core/components/ChooseCameraButton";
import { ViewStreamStatusChangedSubscription } from "core/subscriptions";
import ContentIsNotAvailableError from "core/components/ContentIsNotAvailableError";
import { useTranslation } from "react-i18next";
import { useCast } from "core/components/CastProvider";
import CastLiveScreen from "core/components/CastLiveScreen";
import StreamScreenContent from "core/components/StreamScreenContent";
import moment from "core/utils/moment";

// Дергаем событие video watch не чаще чем раз в 45 секунд
const createEvent45000 = debounce(createEvent, 45 * 1000);

const ONLINE = "ONLINE";
const OFFLINE = "OFFLINE";

export default function StreamScreen({
  closeRouteDialogButton,
  controls,
  onPlay,
  onPause,
  onEnded,
  content,
  view,
  muted,
  hideTitle,
  hideRelatedContent,
  hideShare,
  hideLogo,
  autoPlay,
  autoPause,
  fullscreenTarget,
  onChatAdv,
  sponsorCornerBannerPlace,
  sponsorInStreamBannerPlace,
  sponsorPlayerLogoPlace,
  sponsorPlayerButtonPlace,
  sponsorVideoPlace,
  sponsorChatMessagePlace,
  clientIp,
  targetStreamId,
  viewDataTracker,
  startOffset,
  onVideoError,
  coverFitMode,
}) {
  const { token } = view;
  const viewId = view.id;
  const videoRef = useRef();
  const [cornerBannerHeight, setCornerBannerHeight] = useState(null);
  const [inStreamBannerHeight, setInStreamBannerHeight] = useState(null);
  const [pause, setPause] = useState(false);
  const liveSeekBar = useLiveSeekBar(content.streamData.startAt, {
    startOffset,
  });
  const [trackedEvents, setTrackedEvents] = useState([]);
  const { t } = useTranslation("core");
  const streamsById = view.streams.reduce(
    (all, stream) => Object.assign(all, { [stream.id]: stream }),
    {}
  );
  const cast = useCast();

  /**
   * Просмотренные отрезки с их меткой старта
   */
  const [playedWithMarks, setPlayedWithMarks] = useState([]);

  /**
   * Данные о просмотрах в разных офсетах
   * храним duration и начало просмотра
   */
  const [offsetsData, setOffsetsData] = useState({});

  /**
   * Если мы уже текущий стрим на телевизоре то оттуда может приехать свой offset dvr
   * выставим его в liveSeekBar для синхронизации состояния на тв и в браузере
   */
  useEffect(() => {
    if (
      cast.currentOffset &&
      cast.currentContentId === content.id &&
      cast.playerState &&
      cast.currentOffset !== liveSeekBar.offset
    ) {
      liveSeekBar.setOffset(cast.currentOffset);
    }
  }, [cast.currentOffset]);

  const [streamId, setStreamId] = useState(
    streamsById[targetStreamId] ? targetStreamId : view.streams[0]?.id
  );
  const [streams, setStreams] = useState(streamsById);

  useSubscription(ViewStreamStatusChangedSubscription, {
    skip: !process.browser,
    variables: {
      clientIp,
      contentId: content.id,
      token,
    },
    onData: ({ data }) => {
      if (!data?.data) {
        return;
      }

      const newStreams = data.data.viewStreamStatusChanged.streams;

      const newStreamsById = newStreams.reduce((all, item) => {
        // Если поменялся cdn берем новый стрим целиком
        if (item.playlistCdn !== streams[item.id]?.playlistCdn) {
          all[item.id] = item;
        } else {
          all[item.id] = streams[item.id] || item;
        }

        return all;
      }, {});

      setStreams(newStreamsById);

      if (!newStreamsById[streamId]) {
        setStreamId(newStreams[0]?.id);
      }
    },
  });

  const cameraStreams = Object.values(streams);

  if (cameraStreams.length === 0) {
    return (
      <ContentIsNotAvailableError
        title={t("contentIsNotAvailable.noStream.title")}
        text={t("contentIsNotAvailable.noStream.text")}
        button={null}
        content={content}
        hideTitle={hideTitle}
        hideShare={hideShare}
        hideLogo={hideLogo}
        fullscreenTarget={fullscreenTarget}
        closeRouteDialogButton={closeRouteDialogButton}
      />
    );
  }

  /**
   * Найдем текущий стрим или любой который онлайн
   */
  const stream =
    cameraStreams?.find((stream) => stream.id === streamId) ||
    cameraStreams?.find((stream) => stream.status === ONLINE);

  /**
   * находим ссылку на плейлист в исходном списке стримов?
   */
  const hlsStreamPlaylist = streams[stream.id]?.playlist;

  if (!hlsStreamPlaylist || stream.status === OFFLINE) {
    return (
      <StreamOfflineScreen
        content={content}
        token={token}
        closeRouteDialogButton={closeRouteDialogButton}
      />
    );
  }

  const handleEnded = () => {
    if (!trackedEvents.includes("end")) {
      createEvent("stream", "end");
      setTrackedEvents((trackedEvents) => trackedEvents.concat("end"));
    }

    if (onEnded) {
      onEnded();
    }
  };

  const _onPlay = () => {
    if (onPlay) {
      onPlay();
    }
    if (pause) {
      setPause(false);
    }
  };

  /**
   * Переводит секундные периоды проигрывания с момента startAt к временным меткам
   * [2,12], 2022-01-01 13:00:00 => {from: 2022-01-01 13:00:00, to:  2022-01-01 13:00:10}
   */
  const playedToTimestamps = ({ played, startAt }) => {
    /**
     * Сдвиг первого периода(0 не бывает ибо хлс не с начала плейлиста играет)
     * [[2,10],[20,30]]
     */
    const startDiff = played[0];
    const startMark = startAt.getTime();

    const timeIntervals = played.map((item) => {
      const [periodStart, periodEnd] = item;
      const duration = periodEnd - periodStart;

      const from = moment(startAt)
        .subtract(startDiff, "seconds")
        .add(periodStart, "seconds");
      const to = moment(from).add(duration, "seconds");

      return {
        startMark,
        duration,
        from: from.toDate(),
        to: to.toDate(),
      };
    });

    return { timeIntervals, currentStartMark: startMark };
  };

  /**
   * Обрабатываем проигранные кусочки
   * Так как при изменении offset старые значения пропадают
   * Записываем значения в стейт playedWithMarks
   */
  const processPlayed = (played, { debounce } = {}) => {
    const offset = liveSeekBar.offset;
    const offsetData = offsetsData[offset];
    const offsetDuration = offsetData?.duration || 0;
    const offsetStartAt = offsetData?.startAt;

    let startAt = offsetStartAt;

    const [interval] = played;

    if (!interval) {
      return;
    }

    const duration = played.reduce((all, interval) => {
      return all + (interval[1] - interval[0]);
    }, 0);

    /**
     * Если для офсета уменьшилась продолжительность просмотра
     * значит мотали перематывали и надо сбросить startAt
     */
    if (offsetDuration > duration) {
      startAt = null;
    }

    /**
     * Если нет startAt что происходит в начале просмотра
     * то рассчитываем его из текущей offset, duration плеера и текущего времени
     */
    if (!startAt) {
      startAt = moment()
        .subtract(liveSeekBar.offset, "seconds")
        .subtract(duration, "seconds")
        .toDate();
    }

    /**
     * Записываем текущую продолжительность и startAt для текущего offset
     */
    setOffsetsData((prev) => {
      return Object.assign({}, prev, { [offset]: { duration, startAt } });
    });

    /**
     * Собираем на основе played и startAt временные интервалы просмотра
     * как идентификатор текущего просмотра будет startAt в миллисекундах
     */
    const { timeIntervals, currentStartMark } = playedToTimestamps({
      played,
      startAt,
    });

    /**
     * Берем сохраненные интервалы для всех оффсетов
     * Отбрасываем c текущим currentStartMark что бы его добавить их свежими значениями
     */
    const toSendTimeIntervals = playedWithMarks.filter((period) => {
      return period.startMark !== currentStartMark;
    });

    toSendTimeIntervals.push(...timeIntervals);

    const updateData = {
      trackDuration: {
        timeIntervals: toSendTimeIntervals.map(({ from, to }) => ({
          from,
          to,
        })),
      },
    };

    debounce
      ? viewDataTracker.trackDebounce(updateData)
      : viewDataTracker.track(updateData);

    /**
     * Записываем текущие интервалы в стейт всех интервалов
     */
    setPlayedWithMarks((prev) => {
      let newVal = [...prev];

      /**
       * Уберем старые записи о текущем интервале
       */
      newVal = newVal.filter((period) => {
        return period.startMark !== currentStartMark;
      });

      newVal.push(...timeIntervals);

      return newVal;
    });
  };

  const onUpdate = ({ played }) => {
    createEvent45000("video", "watch");

    processPlayed(played, { debounce: true });
  };

  const onVideoUnmount = ({ played }) => {
    if (!played) {
      return;
    }

    processPlayed(played, { debounce: false });
  };

  const hlsUrl = getStreamPlaylistWithOffset({
    playlist: hlsStreamPlaylist,
    offset: liveSeekBar.offset,
  });

  const chooseCameraButton =
    cameraStreams.length > 1 ? (
      <ChooseCameraButton
        cameras={cameraStreams}
        cameraId={streamId}
        setCameraId={setStreamId}
      />
    ) : null;

  const relatedContent =
    !content.hideRelatedContent && !hideRelatedContent ? (
      <RelatedVideo />
    ) : null;

  const preview = content.preview;

  if (cast.currentContentId === content.id && cast.playerState) {
    /**
     * передаем текущее значение offset и setOffset для синхронизации состояний
     * @TODO нужно из каста как то научится добывать played для updateViewData
     */
    return (
      <CastLiveScreen
        hlsStreamPlaylist={hlsStreamPlaylist}
        offset={liveSeekBar.offset}
        setOffset={(newOffset) => liveSeekBar.setOffset(newOffset)}
        content={content}
        hideTitle={hideTitle}
        hideShare={hideShare}
        hideLogo={hideLogo}
        closeRouteDialogButton={closeRouteDialogButton}
      />
    );
  }

  return (
    <Video
      live
      blur={pause}
      muted={muted}
      autoPlay={autoPlay}
      hlsUrl={hlsUrl}
      drm={stream.drmPlayerSettings}
      onEnded={handleEnded}
      onUpdate={onUpdate}
      onError={onVideoError}
      onPlay={_onPlay}
      onPause={onPause}
      videoRef={videoRef}
      mediaId={stream.id}
      viewId={viewId}
      content={content}
      onUnmount={onVideoUnmount}
      coverFitMode={coverFitMode}
    >
      {(videoElement, videoState, videoActions, videoRef) => {
        return (
          <StreamScreenContent
            closeRouteDialogButton={closeRouteDialogButton}
            autoPlay={autoPlay}
            videoElement={videoElement}
            videoState={videoState}
            videoActions={videoActions}
            relatedContent={relatedContent}
            chooseCameraButton={chooseCameraButton}
            inStreamBannerHeight={inStreamBannerHeight}
            setInStreamBannerHeight={setInStreamBannerHeight}
            cornerBannerHeight={cornerBannerHeight}
            setCornerBannerHeight={setCornerBannerHeight}
            videoRef={videoRef}
            sponsorCornerBannerPlace={sponsorCornerBannerPlace}
            sponsorPlayerLogoPlace={sponsorPlayerLogoPlace}
            sponsorPlayerButtonPlace={sponsorPlayerButtonPlace}
            sponsorInStreamBannerPlace={sponsorInStreamBannerPlace}
            sponsorVideoPlace={sponsorVideoPlace}
            autoPause={autoPause}
            fullscreenTarget={fullscreenTarget}
            content={content}
            muted={muted}
            pause={pause}
            hideTitle={hideTitle}
            hideShare={hideShare}
            hideLogo={hideLogo}
            setPause={setPause}
            onChatAdv={onChatAdv}
            sponsorChatMessagePlace={sponsorChatMessagePlace}
            preview={preview}
            liveSeekBar={liveSeekBar}
            hlsStreamPlaylist={hlsStreamPlaylist}
            onVideoError={onVideoError}
            controls={controls}
          />
        );
      }}
    </Video>
  );
}

StreamScreen.propTypes = {
  onPlay: PropTypes.func,
  onPause: PropTypes.func,
  onEnded: PropTypes.func,
  fullscreenTarget: PropTypes.string,
  clientIp: PropTypes.string,
  muted: PropTypes.bool,
  loop: PropTypes.bool,
  controls: PropTypes.bool,
  hideShare: PropTypes.bool,
  hideLogo: PropTypes.bool,
  hideRelatedContent: PropTypes.bool,
  hideTitle: PropTypes.bool,
  autoPlay: PropTypes.bool,
  autoPause: PropTypes.bool,
  targetStreamId: PropTypes.string,
  coverFitMode: PropTypes.bool,
  content: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    preview: PropTypes.string,
    shareUrl: PropTypes.string.isRequired,
    duration: PropTypes.number,
    hideRelatedContent: PropTypes.bool,
    isAdvEnabled: PropTypes.bool,
    hideLogo: PropTypes.bool,
    widget: PropTypes.object,

    relatedContent: PropTypes.arrayOf(PropTypes.object),
    rightholder: PropTypes.shape({
      id: PropTypes.string.isRequired,
      hideRelatedContent: PropTypes.bool,
    }),
    streamData: PropTypes.shape({
      startAt: PropTypes.string,
      status: PropTypes.string.isRequired,
    }),
  }),
  view: PropTypes.shape({
    id: PropTypes.string.isRequired,
    token: PropTypes.string.isRequired,
    ad: PropTypes.object,
    streams: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        status: PropTypes.oneOf([ONLINE, OFFLINE]).isRequired,
        playlist: PropTypes.string.isRequired,
        lastPublishAt: PropTypes.string,
      })
    ),
  }),

  onChatAdv: PropTypes.func,
  viewDataTracker: PropTypes.object.isRequired,
  sponsorCornerBannerPlace: PropTypes.string,
  sponsorInStreamBannerPlace: PropTypes.string,
  sponsorPlayerLogoPlace: PropTypes.string,
  sponsorPlayerButtonPlace: PropTypes.string,
  sponsorVideoPlace: PropTypes.string,
  sponsorChatMessagePlace: PropTypes.string,
  startOffset: PropTypes.number,
  onVideoError: PropTypes.func,
  closeRouteDialogButton: PropTypes.node,
};
