import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";
import { useArena } from "core/components/ArenaProvider";
import PaymentTerms from "./PaymentTerms";
import ProductPlayer from "arena/components/ProductPlayer";
import PurchaseButtons from "./PurchaseButtons";
import DonateButtons from "./DonateButtons";
import DonationProgress from "arena/components/DonationProgress";
import getPeriodTitleWithCount from "arena/utils/getPeriodTitleWithCount";
import getPricePeriod from "arena/utils/getPricePeriod";
import AccessProductsPayment from "./AccessProductsPayment";
import ThumbnailCardMedia from "arena/components/ThumbnailCardMedia";

const PPV = "PPV";
const SUBSCRIPTION = "SUBSCRIPTION";
const DONATION = "DONATION";
const PREFIX = "ProductPayment";

const classes = {
  root: `${PREFIX}-root`,
  appBar: `${PREFIX}-appBar`,
  composition: `${PREFIX}-composition`,
  li: `${PREFIX}-li`,
  head: `${PREFIX}-head`,
  tab: `${PREFIX}-tab`,
  content: `${PREFIX}-content`,
  indicator: `${PREFIX}-indicator`,
  player: `${PREFIX}-player`,
  media: `${PREFIX}-media`,
};

const StyledCard = styled(Card)(({ theme }) => ({
  [`&.${classes.root}`]: {
    // backdropFilter: "blur(12px)",
    // backgroundColor: "rgba(255,255,255,.05)",
    // [theme.breakpoints.down("sm")]: {
    //   // borderRadius: 0,
    // },
  },

  [`& .${classes.player}`]: {
    zIndex: 1,
    position: "relative",
    height: 0,
    paddingTop: "56.25%",
    background: "black",
    overflow: "hidden",

    "&:fullscreen": {
      paddingTop: 0,
    },
  },

  [`& .${classes.appBar}`]: {
    // backdropFilter: "blur(12px)",
    backgroundColor: "rgba(0,0,0,.25)",
  },

  [`& .${classes.composition}`]: {
    margin: 0,
    padding: theme.spacing(0, 2),
  },

  [`& .${classes.li}`]: {
    marginBottom: theme.spacing(1),
  },

  [`& .${classes.tab}`]: {
    fontSize: "1rem",
    padding: theme.spacing(1, 2),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(1, 3),
    },
  },

  [`& .${classes.content}`]: {
    // overflow: "hidden",
    padding: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(3),
    },
  },

  [`& .${classes.indicator}`]: {
    height: 4,
  },
}));

const DEFAULT = "DEFAULT";
const HIGH = "HIGH";
const MAXRES = "MAXRES";
const MEDIUM = "MEDIUM";

const StyledThumbnailCardMedia = styled(ThumbnailCardMedia)(() => ({
  width: "100%",
  position: "relative",
  height: 0,
  paddingTop: "56.25%", // 16:9
  backgroundColor: "black",
}));

/**
 * Получить заголовок для карточки продукта
 * @param {Object} product - Продукт
 * @param {Function} t - Это функция интернациализации
 * @returns {String}
 */
function getTitle(product, t) {
  const titles = {
    DONATION: t("productPayment.donation"),
    PPV: t("productPayment.ppv"),
    SUBSCRIPTION: t("productPayment.subscription"),
  };

  return titles[product.type];
}

/**
 * Получить название для "таба".
 * @param {Object} product - Продукт
 * @param {Object} price - Это может быть цена (product.prices) или донат (product.donates)
 * @param {Function} t - Это функция для интернациализации
 * @returns {String}
 */
function getLabel(product, price, t) {
  if (price.title) {
    return price.title;
  }

  if (product.type === SUBSCRIPTION) {
    if (price.minimumBillingCycles > 1) {
      return getPeriodTitleWithCount({
        t,
        period: price.period,
        minimumBillingCycles: price.minimumBillingCycles,
      });
    } else {
      return getPricePeriod({
        t,
        period: price.period,
      });
    }
  }

  return t("productPayment.periodDay", { count: price.period });
}

function ProductPayment({
  product,
  promocodeIds,
  setPromocodeIds,
  onClickPriceButton,
  refetch,
}) {
  const arena = useArena();
  const { t } = useTranslation("arena");
  const [tab, setTab] = useState(0);
  const price = product.prices[tab];
  const title = getTitle(product, t);

  const handleChange = (event, tab) => {
    event.preventDefault();
    setTab(tab);
    ReactGA.event({
      category: "Product",
      action: "Change product payment tab",
    });
  };

  const onCompletedPromocodeButton = (data) => {
    if (data.promocode) {
      setPromocodeIds((ids) => ids.concat(data.promocode.id));
      ReactGA.event({
        category: "Product",
        action: "Activate promocode",
        label: data.promocode.id,
      });
    }
  };

  if (product.accessError) {
    const ACCESS_ERRORS = {
      SALES_FINISHED: t("productPayment.salesFinished"),
      ACCESS_FINISHED: t("productPayment.salesFinished"),
      NOT_AVAILABLE_FOR_COUNTRY: t("productPayment.notAvailableForCountry"),
      NOT_AVAILABLE_BY_PROVIDERS: t("productPayment.notAvailable"),
      UNKNOWN: t("productPayment.notAvailable"),
    };

    if (product.accessError === "NOT_AVAILABLE_BY_ACCESS_PRODUCTS") {
      return (
        <StyledCard className={classes.root} elevation={0}>
          <CardContent className={classes.content}>
            <AccessProductsPayment product={product} refetch={refetch} />
          </CardContent>
        </StyledCard>
      );
    }

    return (
      <StyledCard className={classes.root} elevation={0}>
        <AppBar
          color="default"
          elevation={0}
          position="static"
          className={classes.appBar}
        >
          <CardHeader
            title={title}
            titleTypographyProps={{
              variant: "h5",
            }}
          />
        </AppBar>
        <CardContent className={classes.content}>
          <Typography variant="body1" color="textPrimary">
            {ACCESS_ERRORS[product.accessError] || ACCESS_ERRORS.UNKNOWN}
          </Typography>
        </CardContent>
      </StyledCard>
    );
  }

  const showTabs = product.prices.length > 1 || product.donates.length > 1;

  return (
    <StyledCard className={classes.root} elevation={0}>
      {product.video ? (
        <div id="product-player" className={classes.player}>
          <ProductPlayer
            fullscreenTarget="product-player"
            getIpUrl={product.getIpUrl}
            productId={product.id}
          />
        </div>
      ) : (
        <StyledThumbnailCardMedia thumbnails={product?.preview?.thumbnails} />
      )}

      {showTabs && (
        <AppBar
          color="default"
          elevation={0}
          position="static"
          className={classes.appBar}
        >
          <Tabs
            value={tab}
            onChange={handleChange}
            // variant="scrollable"
            scrollButtons="auto"
            indicatorColor="primary"
            textColor="inherit"
            variant="fullWidth"
            centered
            classes={{ indicator: classes.indicator }}
          >
            {product.prices.map((price, index) => {
              const label = getLabel(product, price, t);

              return <Tab label={label} key={index} className={classes.tab} />;
            })}
            {product.donates.map((donate, index) => {
              const label = donate.currency;

              return <Tab label={label} key={index} className={classes.tab} />;
            })}
          </Tabs>
        </AppBar>
      )}

      <CardContent className={classes.content}>
        <Grid container spacing={2}>
          {/* <Grid item xs={12}>
            <ul className={classes.composition}>
              {product.composition.map((item, index) => (
                <Typography
                  variant="body2"
                  color="textPrimary"
                  key={index}
                  component="li"
                  className={classes.li}
                >
                  {item}
                </Typography>
              ))}
            </ul>
          </Grid> */}

          {[SUBSCRIPTION, PPV].includes(product.type) && (
            <PurchaseButtons
              tab={tab}
              product={product}
              onClickPriceButton={onClickPriceButton}
              onCompletedPromocodeButton={onCompletedPromocodeButton}
              promocodeIds={promocodeIds}
              setPromocodeIds={setPromocodeIds}
            />
          )}

          {product.type === DONATION && (
            <>
              <Grid item xs={12}>
                <DonationProgress product={product} />
              </Grid>
              <DonateButtons
                key={tab}
                tab={tab}
                product={product}
                onClickPriceButton={onClickPriceButton}
              />
            </>
          )}

          {/* тут дату сл списания для пиписок а для ппв время действия билета */}
          <Grid item xs={12}>
            <Typography
              color="textSecondary"
              variant="caption"
              align="center"
              display="inline"
            >
              {product.type === DONATION
                ? t("productCover.donation") // "Контет будет доступен в течение 30 дней после каждой оплаты"
                : product.type === SUBSCRIPTION
                ? t("productCover.periodSubscription", {
                    purchaseStartAt: price.purchaseStartAt,
                    purchaseExpiredAt: price.purchaseExpiredAt,
                  })
                : t("productCover.periodPpv", {
                    purchaseStartAt: price.purchaseStartAt,
                    purchaseExpiredAt: price.purchaseExpiredAt,
                  })}
            </Typography>{" "}
            <PaymentTerms product={product} arena={arena} />
          </Grid>
        </Grid>
      </CardContent>
    </StyledCard>
  );
}

ProductPayment.propTypes = {
  promocodeIds: PropTypes.array,
  onClickPriceButton: PropTypes.func.isRequired,
  embed: PropTypes.bool,
  product: PropTypes.shape({
    id: PropTypes.string.isRequired,
    getIpUrl: PropTypes.string,
    title: PropTypes.string.isRequired,
    type: PropTypes.oneOf([SUBSCRIPTION, PPV, DONATION]).isRequired,
    composition: PropTypes.arrayOf(PropTypes.string).isRequired,
    shareUrl: PropTypes.string.isRequired,
    siteUrl: PropTypes.string.isRequired,
    finished: PropTypes.bool,
    finishAt: PropTypes.string,
    startAt: PropTypes.string,
    accessError: PropTypes.string,
    video: PropTypes.object,
    preview: PropTypes.shape({
      url: PropTypes.string.isRequired,
      thumbnails: PropTypes.arrayOf(
        PropTypes.shape({
          format: PropTypes.oneOf([DEFAULT, MEDIUM, HIGH, MAXRES]).isRequired,
          url: PropTypes.string.isRequired,
          webpUrl: PropTypes.string,
        })
      ),
    }),
    rightholder: PropTypes.shape({
      sellerInfo: PropTypes.string.isRequired,
    }).isRequired,
    donationProgress: PropTypes.object,
    donates: PropTypes.arrayOf(
      PropTypes.shape({
        currency: PropTypes.string.isRequired,
        amounts: PropTypes.array,
        providers: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string.isRequired,
            token: PropTypes.string.isRequired,
          })
        ),
      })
    ),
    prices: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number.isRequired,
        currency: PropTypes.string.isRequired,
        period: PropTypes.number.isRequired,
        purchaseStartAt: PropTypes.string.isRequired,
        purchaseExpiredAt: PropTypes.string.isRequired,
        trialPeriod: PropTypes.number,
        nextPrice: PropTypes.shape({
          value: PropTypes.number.isRequired,
          dateFrom: PropTypes.string.isRequired,
        }),
        providers: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string.isRequired,
            token: PropTypes.string.isRequired,
          })
        ),
      })
    ),
  }),
  refetch: PropTypes.func,
  promocode: PropTypes.string,
  setPromocodeIds: PropTypes.func.isRequired,
  onClickHelpButton: PropTypes.func.isRequired,
};

export default ProductPayment;
