import {
  init,
  postEvent,
  backButton,
  isTMA,
  isFullscreen,
  retrieveLaunchParams,
  viewport,
} from "@telegram-apps/sdk";
import { useEffect } from "react";
import { GetUrlByGuidQuery } from "arena/queries";
import { useQuery } from "@apollo/client";

export const isTelegramMiniApp = isTMA("simple");
export const isTelegramMiniAppOnFullscreen =
  isTelegramMiniApp && isFullscreen();

if (isTelegramMiniApp) {
  // Init the package and actualize all global dependencies.
  init({
    acceptCustomStyles: false,
  });

  const launchParams = retrieveLaunchParams();

  console.log("launchParams", launchParams);

  postEvent("web_app_expand");
  postEvent("web_app_setup_closing_behavior", { need_confirmation: false }); // eslint-disable-line camelcase
  postEvent("web_app_setup_swipe_behavior", { allow_vertical_swipe: false }); // eslint-disable-line camelcase

  if (viewport.mount.isAvailable()) {
    viewport.mount();
    console.log("viewport mounted", viewport.isMounted()); // true
    console.log("viewport.safeAreaInsets", viewport.safeAreaInsets());
    console.log(
      "viewport.contentSafeAreaInsets",
      viewport.contentSafeAreaInsets()
    );
  }
}

export function useReactRouterIntegration(location, navigate) {
  useEffect(() => {
    if (!isTelegramMiniApp) return;

    postEvent("web_app_setup_back_button", {
      is_visible: location.pathname !== "/", // eslint-disable-line camelcase
    });

    // When a user clicked the back button, go back in the
    // navigation history.
    if (backButton.onClick.isAvailable()) {
      const off = backButton.onClick(() => {
        off();
        navigate(-1);
      });

      return off;
    }
  }, [location, navigate]);

  useEffect(() => {
    if (!isTelegramMiniApp) return;

    postEvent("web_app_setup_title", { title: document.title }); // eslint-disable-line camelcase
  }, [location]);

  let startParam;
  if (isTelegramMiniApp) {
    const launchParams = retrieveLaunchParams();
    startParam = launchParams?.startParam;
  }

  useQuery(GetUrlByGuidQuery, {
    skip: !isTelegramMiniApp || !startParam,
    variables: { guid: startParam },
    onCompleted: (data) => {
      if (data?.getUrlByGuid) {
        navigate(data.getUrlByGuid);
      }
    },
  });
}
