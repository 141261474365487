import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import DoneIcon from "@mui/icons-material/Done";
import { useLazyQuery } from "@apollo/client";
import { PromocodeQuery } from "core/queries";
import { useTranslation } from "react-i18next";
import formatCurrency from "core/utils/currency";
import { useSearchParams } from "react-router-dom";
import ProductPromocodes from "arena/components/ProductPromocodes";
import { useViewer } from "core/components/ViewerProvider";

const TRIAL = "TRIAL";
const SUBSCRIPTION = "SUBSCRIPTION";
const PPV = "PPV";
const DONATION = "DONATION";

function getHelperText(promocode, currency, productType, error, t) {
  if (!promocode) return null;

  if (error) return t("promocodeButton.invalidCode");

  if (promocode.type === TRIAL) {
    return t("promocodeButton.givesTrial");
  }

  let text = [t("promocodeButton.gives")];

  if (productType === SUBSCRIPTION) {
    if (promocode.discount) {
      text.push(
        t("promocodeButton.discountFirstPayment", {
          discount: promocode.discount,
        })
      );
    }

    if (promocode.amount) {
      text.push(
        t("promocodeButton.amountFirstPayment", {
          amount: formatCurrency(promocode.amount, currency),
        })
      );
    }
  } else {
    if (promocode.discount) {
      text.push(
        t("promocodeButton.discount", { discount: promocode.discount })
      );
    }

    if (promocode.amount) {
      text.push(
        t("promocodeButton.amount", {
          amount: formatCurrency(promocode.amount, currency),
        })
      );
    }
  }

  return text.join(" ");
}

export default function PromocodeButton({
  promocodeIds,
  setPromocodeIds,
  productId,
  price,
  onCompleted,
  productType,
}) {
  const viewer = useViewer();
  const [searchParams] = useSearchParams();
  const promocodeFromUrl = searchParams.get("promocode");

  const { t } = useTranslation("arena");
  const [open, setOpen] = useState(false);
  const [code, setCode] = useState(promocodeFromUrl || "");

  const [fetchPromocode, { data, loading, error }] = useLazyQuery(
    PromocodeQuery,
    {
      skip: !code,
      ssr: false,
      variables: {
        code,
        productId,
      },
      onCompleted,
    }
  );

  const onClickUse = (event) => {
    event.preventDefault();
    fetchPromocode();
  };

  const onClickUsePromocode = (event) => {
    event.preventDefault();
    setOpen(true);
  };

  const onChangeCode = (event) => {
    event.preventDefault();
    setCode(event.target.value);
  };

  useEffect(() => {
    if (promocodeFromUrl) {
      setOpen(true);
      fetchPromocode();
    }
  }, [promocodeFromUrl]);

  const promocode = data?.promocode;

  let helperText = getHelperText(
    promocode,
    price.currency,
    productType,
    error,
    t
  );

  if (error) {
    const errors = {
      NOT_STARTED: t("promocodeButton.promocodePeriodNotStartedError"),
      EXPIRED: t("promocodeButton.promocodePeriodExpiredError"),
      PRODUCT_ALREADY_PURCHASED: t(
        "promocodeButton.productAlreadyPurchasedError"
      ),
      ONLY_ONCE: t("promocodeButton.onlyOnceUsePromocodeError"),
      LIMIT_REACHED: t("promocodeButton.limitReachedError"),
      NOT_FOUND: t("promocodeButton.notFoundError"),
      DEFAULT: t("promocodeButton.error"),
    };

    const cause = error.graphQLErrors?.[0]?.extensions?.cause;

    helperText = errors[cause] || errors.DEFAULT;
  }

  if (open) {
    return (
      <Stack spacing={2}>
        {viewer && (
          <ProductPromocodes
            promocodeIds={promocodeIds}
            setPromocodeIds={setPromocodeIds}
          />
        )}
        <TextField
          label={t("promocodeButton.label")}
          fullWidth
          autoFocus
          variant="outlined"
          value={code}
          onChange={onChangeCode}
          disabled={!!promocode}
          error={!!error}
          helperText={helperText}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {promocode ? (
                  <DoneIcon />
                ) : (
                  <Button onClick={onClickUse}>
                    {t("promocodeButton.activate")}
                  </Button>
                )}
              </InputAdornment>
            ),
          }}
        />
      </Stack>
    );
  }

  return (
    <Button
      disableElevation
      fullWidth
      onClick={onClickUsePromocode}
      disabled={loading}
      size="small"
      color="inherit"
    >
      {t("promocodeButton.useButton")}
    </Button>
  );
}

PromocodeButton.propTypes = {
  setPromocodeIds: PropTypes.func.isRequired,
  promocodeIds: PropTypes.array,
  productId: PropTypes.string.isRequired,
  productType: PropTypes.oneOf([SUBSCRIPTION, PPV, DONATION]).isRequired,
  price: PropTypes.shape({
    currency: PropTypes.string.isRequired,
  }),
  onCompleted: PropTypes.func.isRequired,
};
