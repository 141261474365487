import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
// import IconButton from "@mui/material/IconButton";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Link from "arena/components/Link";
import { useTranslation } from "react-i18next";
// import classNames from "classnames";
// import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import ToggleRenewProductPurchase from "arena/components/ToggleRenewProductPurchase";
import formatCurrency from "core/utils/currency";
import ThumbnailCardMedia from "arena/components/ThumbnailCardMedia";
import StarsIcon from "core/components/StarsIcon";
// import getPriceButtonText from "core/utils/getPriceButtonText";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
// import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import Chip from "@mui/material/Chip";

const PREFIX = "ProductPurchaseListItem";

const classes = {
  root: `${PREFIX}-root`,
  cardHeader: `${PREFIX}-cardHeader`,
  cardContent: `${PREFIX}-cardContent`,
  media: `${PREFIX}-media`,
  deleted: `${PREFIX}-deleted`,
  icon: `${PREFIX}-icon`,
  button: `${PREFIX}-button`,
  iconButton: `${PREFIX}-iconButton`,
  iconButtonActive: `${PREFIX}-iconButtonActive`,
  active: `${PREFIX}-active`,
};

const StyledCard = styled(Card)(({ theme }) => ({
  [`&.${classes.root}`]: {
    position: "relative",
    width: "100%",
    // display: "flex",
    [theme.breakpoints.up("sm")]: {
      transition: "transform .15s ease-in-out",
      transform: "scale(1)",
      "&:hover": {
        transform: "scale(1.03)",
      },
    },
  },

  [`& .${classes.cardHeader}`]: {
    width: "100%",
    flex: 1,
    alignItems: "flex-start",
    color: "rgba(255,255,255,1)",
  },
  [`& .${classes.cardContent}`]: {
    width: "100%",
    flex: 1,
    alignItems: "flex-start",
    paddingTop: 0,
  },

  [`& .${classes.deleted}`]: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.8)",
  },

  [`& .${classes.icon}`]: {
    position: "absolute",
    right: 8,
    top: 8,
    display: "flex",
    alignItems: "center",
  },

  [`& .${classes.button}`]: {
    whiteSpace: "nowrap",
    // width: 126
  },

  [`& .${classes.iconButton}`]: {
    backgroundColor: "rgba(0,0,0,0.9)",
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.9)",
    },
    "&:focus": {
      backgroundColor: "rgba(0,0,0,0.9)",
    },
    "&:active": {
      backgroundColor: "rgba(0,0,0,0.9)",
    },
  },

  [`& .${classes.iconButtonActive}`]: {
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
    },
    "&:active": {
      backgroundColor: theme.palette.primary.main,
    },
  },

  [`& .${classes.active}`]: {
    backgroundColor: "rgba(255,255,255,0.1)",
    color: "rgba(255,255,255,1)",
    "&:hover": {
      backgroundColor: "rgba(255,255,255,0.1)",
    },
    "&:focus": {
      backgroundColor: "rgba(255,255,255,0.1)",
    },
    "&:active": {
      backgroundColor: "rgba(255,255,255,0.1)",
    },
  },
}));

const DEFAULT = "DEFAULT";
const HIGH = "HIGH";
const MAXRES = "MAXRES";
const MEDIUM = "MEDIUM";

const StyledThumbnailCardMedia = styled(ThumbnailCardMedia)(() => ({
  width: "100%",
  position: "relative",
  height: 0,
  paddingTop: "56.25%", // 16:9
  backgroundColor: "black",
}));

export default function ProductPurchaseListItem({ productPurchase }) {
  const { t } = useTranslation("arena");

  const deleted = !productPurchase.product;

  const subHeaders = {
    EXPIRED: t("productPurchaseListItem.isExpiredAt", {
      expiredAt: productPurchase.expiredAt,
    }),
    SUBSCRIPTION: t("productPurchaseListItem.recurrentExpiredAt", {
      expiredAt: productPurchase.expiredAt,
    }),
    SUBSCRIPTION_ALL_CYCLES: t(
      "productPurchaseListItem.recurrentAllCyclesExpiredAt",
      {
        allCyclesExpiredAt: productPurchase.allCyclesExpiredAt,
        createdAt: productPurchase.createdAt,
        expiredAt: productPurchase.expiredAt,
      }
    ),
    PPV: t("productPurchaseListItem.ticketExpiredAt", {
      expiredAt: productPurchase.expiredAt,
    }),
    DONATION: t("productPurchaseListItem.donationExpiredAt", {
      expiredAt: productPurchase.expiredAt,
    }),
  };

  const subheader =
    subHeaders[
      productPurchase.expired
        ? "EXPIRED"
        : productPurchase.allCyclesExpiredAt
        ? "SUBSCRIPTION_ALL_CYCLES"
        : productPurchase.product.type
    ];

  return (
    <StyledCard className={classes.root} style={{ opacity: deleted ? 0.6 : 1 }}>
      <Link to={productPurchase?.product?.siteUrl}>
        <StyledThumbnailCardMedia
          thumbnails={productPurchase?.preview?.thumbnails}
        >
          {deleted && (
            <Typography className={classes.deleted}>
              {t("productPurchaseListItem.deleted")}
            </Typography>
          )}
        </StyledThumbnailCardMedia>
      </Link>

      {/* <div className={classes.icon}>
        <IconButton
          size="small"
          classes={{
            root: classNames(classes.iconButton, {
              [classes.iconButtonActive]: !productPurchase.expired,
            }),
          }}
        >
          <AttachMoneyIcon fontSize="small" />
        </IconButton>
      </div> */}

      <CardHeader
        component={Link}
        to={productPurchase?.product?.siteUrl}
        className={classes.cardHeader}
        title={productPurchase.title}
        titleTypographyProps={{
          variant: "h6",
          gutterBottom: true,
        }}
        subheader={subheader}
        subheaderTypographyProps={{
          variant: "body2",
        }}
      />
      <CardContent className={classes.cardContent}>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <Chip
              icon={
                productPurchase.amount.currency === "XTR" ? (
                  <StarsIcon sx={{ fontSize: "18px" }} />
                ) : productPurchase.product.type === "DONATION" ? (
                  <VolunteerActivismIcon />
                ) : null
              }
              label={
                productPurchase.amount.currency === "XTR"
                  ? productPurchase.amount.value
                  : formatCurrency(
                      productPurchase.amount.value,
                      productPurchase.amount.currency
                    )
              }
              color="default"
              sx={{ fontSize: "1rem", fontWeight: 600, pl: 0.5 }}
            />
          </Grid>
          {productPurchase.recurrent && !productPurchase.expired && (
            <Grid item>
              <ToggleRenewProductPurchase productPurchase={productPurchase} />
            </Grid>
          )}
        </Grid>
      </CardContent>
    </StyledCard>
  );
}

ProductPurchaseListItem.propTypes = {
  productPurchase: PropTypes.shape({
    provider: PropTypes.string,
    renewAvailable: PropTypes.bool,
    disableRenewAvailable: PropTypes.bool,
    recurrent: PropTypes.bool,
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    siteUrl: PropTypes.string.isRequired,
    amount: PropTypes.shape({
      value: PropTypes.number.isRequired,
      currency: PropTypes.string.isRequired,
    }).isRequired,
    daysLeft: PropTypes.number.isRequired,
    createdAt: PropTypes.string.isRequired,
    expiredAt: PropTypes.string.isRequired,
    allCyclesExpiredAt: PropTypes.string,
    renewSubscription: PropTypes.bool,
    expired: PropTypes.bool,
    preview: PropTypes.shape({
      id: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      thumbnails: PropTypes.arrayOf(
        PropTypes.shape({
          format: PropTypes.oneOf([DEFAULT, MEDIUM, HIGH, MAXRES]).isRequired,
          url: PropTypes.string.isRequired,
          webpUrl: PropTypes.string,
        })
      ),
    }),
    product: PropTypes.shape({
      id: PropTypes.string.isRequired,
      siteUrl: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }),
  }),
};
