import { loader } from "graphql.macro";

export const GetUrlByGuidQuery = loader("./GetUrlByGuidQuery.graphql");
export const FavoriteContentsQuery = loader("./FavoriteContentsQuery.graphql");
export const FavoriteContentQuery = loader("./FavoriteContentQuery.graphql");
export const ProductDonationProgressQuery = loader(
  "./ProductDonationProgressQuery.graphql"
);
export const PromocodesQuery = loader("./PromocodesQuery.graphql");
export const FileQuery = loader("./FileQuery.graphql");
export const DevicesQuery = loader("./DevicesQuery.graphql");

export const SignInProvidersQuery = loader("./SignInProvidersQuery.graphql");
export const SettingsQuery = loader("./SettingsQuery.graphql");
export const ArenaOnboardingQuery = loader("./ArenaOnboardingQuery.graphql");
export const CountriesQuery = loader("./CountriesQuery.graphql");
export const TagQuery = loader("./TagQuery.graphql");
export const ArenaBannerQuery = loader("./ArenaBannerQuery.graphql");
export const ArenaLogosQuery = loader("./ArenaLogosQuery.graphql");
export const ArenaPreviewsQuery = loader("./ArenaPreviewsQuery.graphql");
export const ArenaContentsQuery = loader("./ArenaContentsQuery.graphql");
export const ArenaContinueWatchingContentsQuery = loader(
  "./ArenaContinueWatchingContentsQuery.graphql"
);
export const NextArenaContentQuery = loader("./NextArenaContentQuery.graphql");
export const ProductContentsQuery = loader("./ProductContentsQuery.graphql");
export const CollectionsQuery = loader("./CollectionsQuery.graphql");
export const ContentQuery = loader("./ContentQuery.graphql");
export const ContentStreamStatusQuery = loader(
  "./ContentStreamStatusQuery.graphql"
);

export const LanguageOptionsQuery = loader("./LanguageOptionsQuery.graphql");
export const TimeZonesQuery = loader("./TimeZonesQuery.graphql");

export const ProductQuery = loader("./ProductQuery.graphql");
export const ProductsQuery = loader("./ProductsQuery.graphql");
export const PaymentQuery = loader("./PaymentQuery.graphql");
export const ProductVideoQuery = loader("./ProductVideoQuery.graphql");
export const ProductPurchasesQuery = loader("./ProductPurchasesQuery.graphql");

export const PaymentMethodsQuery = loader("./PaymentMethodsQuery.graphql");

export const TagTypesQuery = loader("./TagTypesQuery.graphql");
export const TagsQuery = loader("./TagsQuery.graphql");

export const PostQuery = loader("./PostQuery.graphql");
export const ArenaPostsQuery = loader("./ArenaPostsQuery.graphql");
export const ContentPlaylistQuery = loader("./ContentPlaylistQuery.graphql");
export const NextPlaylistContentQuery = loader(
  "./NextPlaylistContentQuery.graphql"
);
export const ArenaPageQuery = loader("./ArenaPageQuery.graphql");
export const VideoFileQuery = loader("./VideoFileQuery.graphql");

export const CommentsQuery = loader("./CommentsQuery.graphql");

export const ArenaCustomCoversQuery = loader("./ArenaCustomCovers.graphql");
